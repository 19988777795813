footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

footer.mdl-mini-footer {
    padding: 5px 16px;
}

.is-hidden {
    display: none;
}

#mainpage {
  min-height: 100%;
  padding-bottom: 5rem;
}

#startpage {
  min-height: 100%;
  padding-bottom: 5rem;
}

.demo-card-wide.mdl-card {
    position: relative;
    width:100%;
    margin-top: 0em; /*set to a negative number 1/2 of your height*/
    margin-left: 0em; /*set to a negative number 1/2 of your width*/
    border: 1px solid #ccc;
    background-color: #f3f3f3;    
  }
  .demo-card-wide > .mdl-card__title {
    color: #fff;
    height: 176px;
    background: url('./img/icon-256x256.jpg') center / cover;
  }
  .demo-card-wide > .mdl-card__menu {
    color: #fff;
  }

  .card-password {
    width: 100%;
  }
  .card-password > .mdl-card__title {
    color: rgb(37, 32, 23);
  }
  .card-password > .mdl-card__supporting-text {
    font-size: 1.5rem;
  }
  .card-password > .mdl-card__menu {
    color: rgb(20, 14, 14);
  }


  .mdl-js-snackbar {
    z-index: 100;
  }